
.wrapper {
  position: relative;
  width: 25px;
  height: 20px;
  margin: 0 auto;
}
.wrapper .circle {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background-color: #33cc33;
  margin: auto;
  transform: scale(1, 1);
}
.wrapper .circle.pulse {
  animation-timing-function: ease;
  animation: pulse 2s infinite;
  background-color: #99e699;
}
.wrapper svg {
  fill: #279b27;
  stroke: #279b27;
  stroke-linejoin: round;
  stroke-width: 5;
  transition: all 0.3s;
}
.wrapper svg:hover {
  /* cursor: pointer; */
  fill: #228722;
  stroke: #228722;
  transform: scale(1.2, 1.2);
}
@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(2,2);
  }
}
