.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.service-details h2 {
    font-size: 2.75rem;
    font-weight: 400;
    font-family: "Alegreya", Arial;
    text-align: center;
    margin-bottom: 1.25rem;
}
.service-details h5 {
    font-size: 1.0625rem;
    font-family: "Alegreya Sans", sans-serif;
    color: #000000bf;
    padding: 0rem 5.3125rem;
    text-align: center;
    margin-bottom: 2rem;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.service-details img {
    min-height: 7.75rem;
    display: block;
    margin: 0 auto;
}
.service-details h6 {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: "Alegreya", Arial;
    text-align: center;
    color: #f58120;
    margin-top: 0.9375rem;
}



.service-advantage {
    background-color: #eae4da;
    padding: 3.75rem 0rem;
}
.service-advantage .padding {
    padding: 0rem 5.625rem;
}
.service-advantage ul {
    list-style: none;
}
.text-white {
    color: #fff !important;
}
.service-advantage .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.service-advantage h2 {
    font-size: 2.25rem;
    font-weight: 400;
    font-family: "Alegreya", Arial;
    text-align: center;
    margin-bottom: 2.375rem;
}
.service-advantage ul li {
    font-size: 1.0625rem;
    font-weight: 500;
    font-family: "Alegreya Sans", sans-serif;
    color: #000000bf;
}
.service-advantage ul li:before {
    content: "✓";
    color: #f58120;
}
.service-advantage button {
    background-color: #f58120;
    -webkit-box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px 0px;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px 0px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: none;
    outline: none;
    height: 48px;
    width: 213px;
    font-family: "Alegreya Sans", Arial;
    color: white;
    font-size: 16px;
    font-weight: 500;
    max-width: 8.6875rem;
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
}