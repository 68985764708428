
@media screen and (min-width: 600px)  {
  .kundaliCard{
    display: flex;
  }
}
    
@media  screen and (min-width: 360px) and (max-width: 599px)   {
  .kundaliCard{
    display: block ;
  }
}

.text{
    font-family: emoji;
}
/* .container- */
.container-fluid{
    /* padding-left: 0px !important;
    padding-right: 0px !important; */
    padding: 30px 0px !important;
    /* margin-top: 30px; */
    /* margin: 30px 0px 0px 0px !important; */

}
 .container-fluid p {
    font-family: sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    padding-left: 0;
    padding-right:0 ;

    /* background: url("../..//assets/img/abt.png"); */

}
.im{
    background-image: url("../../assets/img/abtbanner.png");
}

label{
    font-weight: bold;
    font-size: 0.9rem;
}
/* .ant-radio-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    font-size: 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    height:30px;
    margin: auto;
    padding: 20px;
    width: 100%;
    align-items: center;
} */




.card-purple-blue {
  background: linear-gradient(45deg, #6d80fe 0%, #23d2fd 100%);
  box-shadow: 0 5px 20px rgba(35, 210, 253, 0.3);
}

.card-salmon-pink {
  background: linear-gradient(45deg, #ff998b 0%, #ff6d88 100%);
  box-shadow: 0 5px 20px rgba(255, 153, 139, 0.3);
}

.card-blue-green {
  background: linear-gradient(45deg, #09afe8 0%, #29f499 100%);
  box-shadow: 0 5px 20px rgba(41, 244, 153, 0.3);
}

.card-purple-pink {
  background: linear-gradient(45deg, #707cff 0%, #fa81e8 100%);
  box-shadow: 0 5px 20px rgba(250, 129, 232, 0.3);
}
.kundaliCard-header {
  background-image: url(../../assets/img/kundali banner.png);
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
  height: 200px;
  position: relative;
}

table {
  width: 100% !important;
}
table caption {
  caption-side: top;
  font-weight: bold;
  color: black;
  font-size: 1.1rem;
  text-transform: uppercase;
  background-color: #dfdfe2;
  padding-left: 1rem;
}
table, th, td {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
}
th, td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 600px) {

  th, td {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
}

table span {
  font-weight: 500;
}

.kundali_title{
    margin: auto;
    margin-right: 42%;
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    font-family: 'Alegreya Sans', sans-serif;
}

.container-fluid table tr p{ 
    font-size: 14px;
}

.panchang_first_card{
  background: linear-gradient(170deg, #C86DD7 0%, #3023AE 100%);
}

.panchang_first_card .ico-card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ico-card> i {
  position: relative;
  left: 73%;
  top: 60%;
  font-size: 20rem;
  line-height: 0;
  opacity: 0.2;
  color: white;
  z-index: 0;
}

.date_element{
  width: fit-content;
  font-size: 4.5rem;
}
.festival-alert{
  background-color: #e9963e;
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}




.btn-flip {
  padding: 0;
  border: none;
  opacity: 1;
  outline: 0;
  color: #fff;
  line-height: 40px;
  position: relative;
  text-align: center;
  letter-spacing: 1px;
  display: inline-block;
  text-decoration: none;
  font-family: 'Open Sans';
  text-transform: uppercase;
}
.btn-flip:hover:after {
  opacity: 1;
  transform: translateY(0) rotateX(0);
}
.btn-flip:hover:before {
  opacity: 0;
  transform: translateY(50%) rotateX(90deg);
}
.btn-flip:after {
  border: 1px solid #C86DD7;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  color: #fff;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: linear-gradient(170deg, #C86DD7 0%, #3023AE 100%);
  content: attr(data-back);
  transform: translateY(-50%) rotateX(90deg);
}
.btn-flip:before {
  border: 1px solid #e9963e;
  top: 0;
  left: 0;
  opacity: 1;
  color: white;
  display: block;
  padding: 0 30px;
  line-height: 40px;
  transition: 0.5s;
  position: relative;
  background: #e9963e;
  content: attr(data-front);
  transform: translateY(0) rotateX(0);
}
