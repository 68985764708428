.input{
    margin-bottom: 0 !important;
}

.suggestionsList{
  margin: 0;
  padding: 0;
    list-style-type: none;
}
.suggestionsContainer{
  display: none;
}
.suggestionsContainerOpen{
  display: block;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  overflow-y: auto;
  max-height: 200px !important;
}
.suggestion{
  cursor: pointer;
  padding: 10px 20px;
}
.suggestionHighlighted{
  background-color: #ddd;
}
