@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital@1&display=swap');
Sbody {
  font-family: "Open Sans", sans-serif;
}
html,body{
  overflow-x: hidden;
  overflow-y: auto;
  height: auto;
}
/****************** Utilities ********************/
.btn-rounded {
  border-radius: 10em;
}

.btn-outline-amber {
  color: #e9963e !important;
  border: 1px solid #e9963e !important;
}

.btn-amber {
  background-color: #e9963e !important;
}

.text-amber {
  color: #e9963e;
}

.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  width: 50px !important;
  height: 80px !important;
  background: #fff;
  padding: 10px;
  -webkit-box-shadow: 1px 2px 10px -1px #0000004d;
          box-shadow: 1px 2px 10px -1px #0000004d;
}

.swiper .swiper-button-next {
  border-radius: 4px 0 0 4px;
  right: 0 !important;
}

.swiper .swiper-button-prev {
  border-radius: 0 4px 4px 0;
  left: 0 !important;
}

.container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}

/****************** Header ***********************/
.Header .top_bar {
  background-color: #e9963e;
  padding: 15px;
}

.Header .top_bar .top_menu {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.Header .top_bar .top_menu li {
  list-style: none;
  margin-right: 30px;
}

.Header .top_bar .top_menu li a {
  color: #fff;
  font-weight: 500;
}

.Header .top_bar .top_menu li a i {
  color: #fff;
  margin-right: 5px;
}

.Header .navbar .navbar-nav {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Header .navbar .navbar-nav .nav-item.wishlist a, .Header .navbar .navbar-nav .nav-item.cart a {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  max-width: 112px;
  position: relative;
}

.Header .navbar .navbar-nav .nav-item.wishlist a span.lnr, .Header .navbar .navbar-nav .nav-item.cart a span.lnr {
  color: #111;
  font-size: 28px;
  font-weight: 500;
  padding-right: 10px;
}

.Header .navbar .navbar-nav .nav-item.wishlist a .count, .Header .navbar .navbar-nav .nav-item.cart a .count {
  background: #e9963e;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  height: 20px;
  left: 45px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: -5px;
  width: 20px;
}

.Header .navbar .navbar-nav .nav-item.active a {
  font-weight: 600;
}

.Header .navbar .navbar-nav .nav-item a {
  font-weight: 500;
  /* margin: auto 10px; */
  text-transform: capitalize;
}

/****************** Banner ***********************/
.App {
  position: relative;
}

.App .loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #ffffffc2;
}

.mybreadcrumb .breadcrumb {
  padding: 30px;
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

.mybreadcrumb .breadcrumb a {
  color: #e9963e;
}

.banner .banner_wrap {
  /*height: 500px;*/
  overflow: hidden;
  aspect-ratio: 16/6;
}

.banner .banner_wrap img {
  width: 100%;
  height: 100%;

}

.ashis {
  padding: 0;
  background-image: url("https://images.theconversation.com/files/194855/original/file-20171115-19836-uy2yzs.jpg?ixlib=rb-1.1.0&q=20&auto=format&w=320&fit=clip&dpr=2&usm=12&cs=strip");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  z-index: 99999999;
  
}
/****************** buynow ***********************/

.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  margin: 0 -16px;
}

.col-25 {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
}

.col-50 {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
}

.col-75 {
  -ms-flex: 75%; /* IE10 */
  flex: 75%;
}

.col-25,
.col-50,
.col-75 {
  padding: 0 16px;
}

.container {
 padding: 5px 20px 15px 20px;
  border-radius: 3px;
}

input[type=text] {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

label {
  margin-bottom: 10px;
  display: block;
}

a {
  color: #2196F3;
}

hr {
  border: 1px solid lightgrey;
}

span.price {
  float: right;
  color: grey;
}

/****************** about_us ***********************/
.about_us {
  padding: 3em 0;
  background-image: url(../img/abtbanner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
}
.about_us .container {
  position: relative;
  z-index: 1;
}

.about_us .abt_content {
  padding: 20px;
  position: relative;
}

.about_us .abt_content h3 {
  font-size: 40px;
  color: #000;
  font-weight: 500;
}

.about_us .abt_content h3 span {
  color: #c74a2d;
  font-weight: 600;
}

.about_us .abt_content p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

/****************** Buyer ***********************/
.Buyer form button, .Buyer form input, .Buyer form textarea{
  width:100%;
  padding:10px;
  border:0.5px solid #000;
}
.Buyer form button{
  background:#fff7ed;
  border:0;
  text-transform: uppercase;
}
.Buyer form button:hover,.Buyer form button:focus{
  background:#e9963e;
  color:#000;
  outline:0;
}
/****************** all_Cart ***********************/
.cart_details {
  background-color: #fafafa;
  padding: 5em 0;
}

.cart_details .all_cart {
  background: #fff;
  -webkit-box-shadow: #00000033 0px 1px 2px 0px;
          box-shadow: #00000033 0px 1px 2px 0px;
  padding: 25px;
}

.cart_details .all_cart .heading {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.cart_details .all_cart .heading h1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}

.cart_details .all_cart .cart_info {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.cart_details .all_cart .cart_info:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.cart_details .all_cart .cart_info .pro_detail .pro_thumb {
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
}

.cart_details .all_cart .cart_info .pro_detail .pro_thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.cart_details .all_cart .cart_info .pro_detail {
  position: relative;
}

.cart_details .all_cart .cart_info .pro_detail .ant-input-number-handler-wrap {
  opacity: 1;
}

.cart_details .all_cart .cart_info .pro_detail .pro_brand {
  font-size: 13px;
  color: #878787;
}

.cart_details .all_cart .cart_info .pro_detail .pro_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.cart_details .all_cart .cart_info .pro_detail .pro_title a {
  color: #000;
}

.cart_details .all_cart .cart_info .pro_detail .pro_price span {
  color: #878787;
  text-decoration: line-through;
  font-size: 14px;
  vertical-align: middle;
  font-weight: 500;
}

.cart_details .all_cart .cart_info .pro_detail .pro_material {
  color: #878787;
}

.cart_details .all_cart .cart_info .pro_detail .pro_action {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.cart_details .price_details {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.cart_details .price_details .checkout_details {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.cart_details .price_details .checkout_details .lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cart_details .price_details .checkout_details .lists span {
  font-size: 14px;
  font-weight: 500;
}

/****************** all_product ***********************/
.all_product {
  padding: 3em 0px 0;
}

.all_product .heading {
  text-align: center;
  margin: 0 0px 3em;
}

.all_product .heading h1 {
  font-size: 50px;
  color: #000;
  font-weight: 500;
  font-family: 'Alegreya Sans', sans-serif;
}

.all_product .heading h1 span {
  color: #c74a2d;
  font-weight: 600;
}

.all_product .swiper .swiper-button-next,
.all_product .swiper .swiper-button-prev {
  width: 30px !important;
  height: 50px !important;
}

.all_product .swiper .swiper-button-next:after,
.all_product .swiper .swiper-button-prev:after {
  font-size: 16px;
  font-weight: 700;
}

.all_product .product_cards {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  border-radius: 25px;
}

.all_product .product_cards .img_wraped {
  height: 200px;
  overflow: hidden;
}

.all_product .product_cards .img_wraped img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.all_product .product_cards:hover .wishlist {
  top: 1%;
}

.all_product .product_cards:hover .action {
  top: 50%;
}

.all_product .product_cards h4 {
  margin-bottom: 5px;
}

.all_product .product_cards h5 {
  font-size: 15px;
}

.all_product .product_cards h5 del {
  color: #c74a2d;
}

.all_product .product_cards .action {
  position: absolute;
  top: -100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.all_product .product_cards .wishlist {
  position: absolute;
  top: -20%;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.all_product .product_cards .wishlist button {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff !important;
  color: #e9963e !important;
}

.all_product .product_cards .wishlist button i {
  font-size: 18px;
}

.product_details {
  padding: 5em 0;
}

.product_details .product_thumb {
  height: 550px;
  overflow: hidden;
}

.product_details .product_thumb img {
  border-radius: 10px;
   /*width: 100%;*/
  height: 100%; 
  
  -o-object-fit: cover;
     object-fit: cover;
}

.product_details .product_desc {
  padding: 20px;
}

.product_details .product_desc .quantity {
  width: 100%;
}

.product_details .product_desc .quantity input {
  height: calc(1.5em + 0.75rem + 2px);
}

.product_details .product_desc .variants {
  margin: 2em 0;
}

.product_details .product_desc .variants h5 {
  color: #000;
  font-size: 15px;
}

.product_details .product_desc .variants .size .ant-radio-button-wrapper {
  margin-right: 10px;
  padding: 5px 20px;
  height: auto;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.product_details .product_desc .variants .size .ant-radio-button-wrapper:hover {
  color: #e9963e;
}

.product_details .product_desc .variants .size .ant-radio-button-wrapper:before {
  display: none;
}

.product_details .product_desc .variants .size .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: #e9963e;
  border-color: #e9963e;
}

.product_details .product_desc .variants .size .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
  color: #fff;
}

.product_details .product_desc .variants .color_filter {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.product_details .product_desc .variants .color_filter .color {
  margin-right: 10px;
  border: 1px solid #0000003f;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 0;
  cursor: pointer;
}

.product_details .product_desc .variants .color_filter .color.active {
  border: 3px solid #b5b5b5;
}

.product_details .product_desc .variants_samagri {
  margin-top: 20px;
}

.product_details .product_desc .variants_samagri .pricediv {
  width: 75px;
  margin-right: 10px;
  padding: 7px;
  height: auto;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.product_details .product_desc .variants_samagri .pricediv.active {
  background-color: #e9963e;
  color: #fff;
}

.product_details .product_desc .product_prices h3 {
  color: #e9963e;
}

.product_details .product_desc .product_prices h3 span {
  color: #878787;
  font-size: 16px;
  font-weight: 400;
}

.product_details .product_desc .product_prices h5 {
  color: #878787;
  font-size: 16px;
  font-weight: 400;
}

.product_details .product_desc h1 {
  font-weight: 600;
  font-size: 40px;
}

.product_details .product_desc h3 {
  color: #e9963e;
  font-weight: 700;
}

.product_details .product_desc p {
  color: #444;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.product_details .product_desc .btn {
  padding: 10px 30px;
  color: #fff;
}

.product_details .more_desc {
  margin: 2em 0;
}

.product_details .more_desc .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #e9963e;
}

.product_details .more_desc .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 20px;
  color: #6e6e6e;
  text-transform: uppercase;
}

.product_details .more_desc .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #000;
}

.product_details .more_desc .ant-tabs-nav-list .ant-tabs-ink-bar {
  background: #e9963e;
}
/************** poojapathtable **************************/


/************** profile **************************/
.profile {
  padding: 5em 0;
}

/*********** Login & Register ********************/
.login {
  padding: 5em 0;
}

/****************** Footer ***********************/
.footer {
  padding: 5em 30px 1em;
  background: #fff7ed;
}

.footer .copyright {
  background-color: #e9963e;
  text-align: center;
  margin: 20px 0;
  padding: 5px;
  border-radius: 5px;
}

.footer .copyright p {
  margin-bottom: 0px;
  color: #fff;
  font-weight: 400;
}

.footer .footer_wrap {
  padding: 0 20px;
}

.footer .footer_wrap .footer_desc p {
  font-size: 16px;
  font-weight: 400;
}

.footer .footer_wrap .footer_logo {
  margin-bottom: 20px;
}

.footer .footer_wrap .footer_head {
  margin-bottom: 20px;
}

.footer .footer_wrap .footer_head h2 {
  font-weight: 500;
  font-size: 25px;
}

.footer .footer_wrap .footer_contact ul {
  margin: 0;
  padding: 0;
}

.footer .footer_wrap .footer_contact ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin-bottom: 10px;
}

.footer .footer_wrap .footer_contact ul li .icons {
  width: 50px;
  height: 50px;
  background-color: #e9963e;
  border-radius: 50%;
  margin-right: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
}

.footer .footer_wrap .footer_contact ul li .box_content h3 {
  font-size: 15px;
  font-weight: 400;
  color: #777;
}

.footer .footer_wrap .footer_contact ul li .box_content p {
  font-size: 15px;
  font-weight: 400;
}

.footer .footer_wrap .footer_contact ul li .box_content p a {
  color: #000;
}

.footer .footer_wrap .footer_links ul {
  margin: 0;
  padding: 0;
}

.footer .footer_wrap .footer_links ul li {
  list-style: none;
  margin-bottom: 10px;
  margin-left: 10px;
}

.footer .footer_wrap .footer_links ul li a {
  color: #000;
  position: relative;
  font-weight: 400;
}

.footer .footer_wrap .footer_links ul li a i {
  color: #e9963e;
  margin-right: 5px;
}

.footer .footer_wrap .newsletter_form {
  margin-top: 10px;
}

.footer .footer_wrap .newsletter_form .content_form {
  position: relative;
}

.footer .footer_wrap .newsletter_form .content_form input {
  padding: 0 25px;
  border-radius: 30px;
  text-align: left;
  width: 100%;
  height: 55px;
  font-size: 16px;
}

.footer .footer_wrap .newsletter_form .content_form .form_action {
  position: absolute;
  top: 0;
  right: -20px;
}

.footer .footer_wrap .newsletter_form .content_form .form_action button {
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 20px;
}

.footer .footer_wrap .newsletter_form .content_form .form_action button i {
  color: #fff;
}


/* .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.mySwiper.swiper-backface-hidden {
  /*height: 100%;*/
/* } */ 


button.btn.btn-lg.stdh-buttons:focus {
    background-color: #e9963e !important;
    box-shadow: none;
    color: white !important;
}

#reviewCol{
    overflow-y: hidden;
}
#reviewCol:hover{
    overflow-y: scroll;
}
#shareButton:focus{
    box-shadow: none;
}


.range-of-services {
    /* z-index: -1; */
    background-color: #eae4da;
    padding-top: 12.9375rem;
    padding-bottom: 6.25rem;
    position: relative;
}
.range-of-services .title {
    font-size: 44px;
    font-family: 'Alegreya Sans', sans-serif;
    line-height: 56px;
    color: rgba(0, 0, 0, 0.87);
}
.title {
    font-size: 18px;
    font-family: 'Alegreya Sans', sans-serif;
    line-height: normal !important;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 700;
    margin-bottom: 8px;
}
.range-of-services .card .title {
    font-size: 20px;
    font-family: 'Alegreya Sans', sans-serif;
    position: relative;
    color: #f58120;
    font-weight: 600;
}
.range-of-services .card {
   /** height: 198px; **/
    width: 274px;
    overflow: hidden;
    -webkit-border-radius: 16px;
    border-radius: 16px;
}
@media only screen and (max-width: 450px)
{
    
.range-of-services .col-md-6 .card {
    float: none;
    margin-bottom: 20px !important;
}
}
.range-of-services .illustration-top {
    width: 81px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    text-align: center;
    opacity: 0.6;
}
.range-of-services .card .background-white {
    background-color: white;
    position: absolute;
    width: 600px;
    height: 112px;
    bottom: -35px;
    left: -62px;
    -webkit-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
}
.range-of-services .illustration-bottom {
    height: 191px;
    position: absolute;
    right: -196px;
    bottom: 58px;
    -webkit-transform: rotate(129deg);
    -ms-transform: rotate(129deg);
    transform: rotate(129deg);
}
.range-of-services .title span {
    color: #e9963e !important;

}

.sizeChartWeb-tableNew {
  border-collapse: collapse;
  margin: 0 auto;
  width: 100%;
}
.sizeChartWeb-newRow {
  border: 1px solid #eaeaec;
}
.sizeChartWeb-newCell {
  padding: 10px 5px;
  text-align: center;
}
@media (min-width: 980px)
{
  .sizeChartWeb-newCell {
      padding: 10px;
  }
}
.sizeChartWeb-cell-title {
  font-weight: 400;
}
.popover{
  max-width: 100%; /* Max Width of the popover (depending on the container!) */
}
.out_of_stock{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}


/* Aashutuysh css start  */

.product_like_share_comment_wrap{
  position: relative;
}
.product_like_share_comment{
  margin: 0 auto;
}
.product_like_share{
  display: flex;
  align-items: center;
  height: 100%;
  background-color: transparent;
}
.product_like{
  margin: 0 0.75rem;
  display: flex;
  flex-direction: column;
}
.product_like .fa-heart {
  font-size: 2rem;
  color: #B80808;
}
.product_like h6 {
  margin: 0 auto;
}
.product_like .fa-heart:hover {
  /* animation
   */
  animation: beat .25s 2 alternate;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  color: #800408;
}
@keyframes beat{
  to { transform: scale(1.4); }
}
.product_comment{
  margin: 0 0.75rem;
  display: flex;
  flex-direction: column;
}
.product_comment .fa-comment {
  font-size: 2rem;
  color: #0869B8;
}
.product_comment .fa-comment:hover {
  /* animation
   */
  animation: beat .25s 2 alternate;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  color: #040880;
}
.product_share .fa-share-alt {
  font-size: 2rem;
  color: #0869B8;
}
.product_share .fa-share-alt:hover {
  /* animation
   */
  animation: beat .25s 2 alternate;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  color: #040880;
}
.product_comments_box .product_comments_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 0.5rem 1rem;
  margin-bottom: -2rem;
}
.product_comments_box .product_comments_head h2 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #000;
}
.product_comment_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: transparent;
}
.product_comment_item_img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;
}
.product_share_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: transparent;
}
.product_share .dropdown .btn {
  padding: 0rem 0.5rem;
  background-color: transparent;
}
.product_share .dropdown .ri-share-fill{
  color: #0869B8;
}

/* Aashutuysh css end */

/* row [class^="col-xs-"], .row [class^="col-sm-"], .row [class^="col-md-"], .row [class^="col-lg-"] {
  min-height: 1px;
  padding-right: 10px;
} */
.panel.panel-default {
  border-top-color: rgb(245, 245, 245);
  border-top-width: 1px;
}
.panel {
  /* float: left; */
  width: 100%;
  border-radius: 0px;
  border-width: 2px 0px 0px;
  border-right-style: initial;
  border-bottom-style: initial;
  border-left-style: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-top-style: solid;
  border-top-color: rgb(229, 229, 229);
  margin-bottom: 20px;
  position: relative;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 1px 0px;
}

.panel .panel-body {
  padding: 15px;
  position: relative;
}

.panel .panel-heading, .panel .panel-footer, .panel .panel-body {
  /* float: left; */
  width: 100%;
}

/* .row {
  margin-left: 0px;
  margin-right: 0px;
} */

/* .col-md-6, label {
  font-weight: bold !important;
} */

.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}

.custominput-group-addon:first-child {
  border-right: 0px;
  display: inline-block;
  float: left;
  line-height: 28px;
}
.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group>.btn, .input-group-btn:last-child>.dropdown-toggle, .input-group-btn:first-child>.btn:not(:first-child), .input-group-btn:first-child>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.help-block {
  color: rgb(170, 178, 189);
  margin-bottom: 0px;
}
.form-group {
  margin-bottom: 15px;
}
.row:before, .row:after ,.panel-body:before, .panel-body:after, .form-horizontal .form-group:before {
  display: table;
  content: " ";
}

.form-horizontal .form-group:after {
  clear: both;
}
.form-horizontal .form-group:before {
  display: table;
  content: " ";
}
.custominput-group { 
  position: relative;  
  border-collapse: separate; 
  width: 100%;
}

.input-group-addon { 
  display:     table-cell;
  width:    1%; 
  white-space:    nowrap; 
  vertical-align:  bottom;
  padding:   0px 10px; 
  font-size:   13px; 
  font-weight:   400; 
  line-height:   28px; 
  color:   #FFF; 
  text-align:   center; 
  background-color:   #33414e; 
  border:   1px solid #ccc; 
  border-radius:   4px;
  border-color:  #33414e; 
  min-width:  36px;
} 
 .custominput-group-addon { 
  border-color: #fe970a; 
  background-color: #fe970a;
} 
.input-group-addon:first-child { 
  border-top-right-radius:  0; 
  border-bottom-right-radius:  0;
  border-right: 0;
}  
.custominput-group-addon:first-child { 
  border-right: 0; 
  display: inline-block; 
  float: left; 
  line-height: 28px;
} 
.custominput-group .form-control  { 
  border-radius: 0; 
  display: inline-block; 
  width: 84%;
} 
.input-group .form-control:last-child  { 
  border-top-left-radius: 0; 
  border-bottom-left-radius: 0;
}
.panel .panel-footer {
  background: #F5F5F5;
  border: 0px;
  border-top: 1px solid #E3E3E3;
  line-height: 30px;
  padding: 10px;
}
.panel .panel-heading, .panel .panel-footer, .panel .panel-body {
  /* float: left; */
  width: 100%;
}
.check {
  font-weight: 400;
  line-height: 20px;
  margin: 5px 0px;
  cursor: pointer;
}
input[type=radio], input[type=checkbox] {
  margin: 2px 0px 0px;
}
.check .icheckbox_minimal-grey, .check .iradio_minimal-grey {
  margin-right: 5px;
  margin-top: -2px;
}
.iradio_minimal-grey {
  background-position: -100px 0;
}
.icheckbox_minimal-grey, .iradio_minimal-grey {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  /* background: url(../img/grey.png) no-repeat; */
  border: none;
  cursor: pointer;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.mySwiper.swiper-backface-hidden {
  height: inherit;
}

.banner_wrap > img.img-fluid {
  display: block;
  margin: auto;
}