.contact-us {
    background-image: url(../img/HeadImg.jpeg);
    background-position: center center;
    background-repeat: repeat;
    -webkit-background-size: cover;
    background-size: cover;
    height: 175px;
    position: relative;
}

.flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    flex-direction: column;
}
.form-row input{
    width: 170% ;
}
.contact-us .title {
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    font-family: 'Alegreya Sans', sans-serif;
}
.contact-us .text {
    font-size: 18px;
    font-family: "Alegreya Sans", sans-serif;
    line-height: normal;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
    text-align: center;
}
.social-media {
    margin-top: 24px;
}
.form-title {
    font-size: 18px;
    font-family: "Alegreya", Arial;
    line-height: normal;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 700;
}

.contact-info {
    padding: 100px 0px;
}


.title {
    font-size: 18px;
    font-family: "Alegreya", Arial;
    line-height: normal;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 700;
    margin-bottom: 8px;
}
.contact-text {
    font-size: 15px;
    line-height: 28px;
    font-family: "Alegreya Sans", sans-serif;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 500;
}
.phone {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.phone .circle {
    height: 28px;
    width: 28px;
    background-color: #fff;
    -webkit-box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px 0px;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px 0px;
    -webkit-border-radius: 24px;
    border-radius: 24px;
}
.email .circle {
    height: 28px;
    width: 28px;
    background-color: white;
    -webkit-box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px 0px;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px 0px;
    -webkit-border-radius: 24px;
    border-radius: 24px;
}
.email {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.text-orange a {
    font-size: 15px;
    line-height: 28px;
    font-family: "Alegreya Sans", sans-serif;
    color: #cd6727;
    font-weight: 500;
    margin-bottom: 2px;
}
.phone .circle .fa-phone {
    -webkit-transform: scale(-1) rotate(-84deg);
    -ms-transform: scale(-1) rotate(-84deg);
    transform: scale(-1) rotate(-84deg);
    color: #f58120;
    font-size: 12px;
}
.email .circle .fa-envelope {
    color: #f58120;
    font-size: 12px;
}

.social-media li .facebook {
    background-color: #3b5998;
    color: #fff;
    margin-left: -32px;
}

.social-media li div {
    width: 40px;
    height: 40px;
    -webkit-border-radius: 16px;
    border-radius: 30px;
}
.social-media li{
    display: inline-block;
    height: 52px;
    width: 52px;
    color: white;
    margin-left: 0px !important;
}
.twitter {
    background-color: #00aced;
    color: #fff;
    padding: 0px;
    /* margin-left: 4px; */
}

.social-media li div {
    width: 40px;
    height: 40px;
    -webkit-border-radius: 16px;
    border-radius: 16px;
}
.social-media li .instagram {
    background-color: #e4405f;
    color: #fff;
    margin-left:-27px;
}

.social-media li div {
    width: 42px;
    height: 42px;
    -webkit-border-radius: 16px;
    border-radius: 16px;
}
.social-media ul{
    list-style: none;
    display: flex;
    margin-left: 5px;
}
.social-media li .twitter {
    background-color: #00aced;
    margin-left: -29px;
}
.wrapper{
    box-shadow: 0px 0px 10px 0px #ccc;
}
.fab, .far {
    font-weight: bolder;
}

.Button{
    background-color: #f58120;
    margin-top: 5px;
    /* padding: 8px; */
    border:none;
} 


.social-media li .email {
    background-color: #f58120;
    margin-left: -29px;
}
.social-media li .youtube {
    background-color: red;
    margin-left: -29px;
}

.title {
    text-shadow: 0 0.3rem 0.5rem rgb(0 0 0 / 30%);
}
.form-title{
    text-shadow: 0 0.3rem 0.5rem rgb(0 0 0 / 30%);
}

.title{
    font-size: 50px;
    color: #000;
    font-weight: 500;
    font-family: 'Alegreya Sans', sans-serif;
}

.contact-us .container {
    padding: 0 !important;
}
.contact-us .container p{
    margin: 0 !important;
}

@media only screen and (max-width: 600px) {
    .contact-us {
    background-image: url(../img/HeadImg.jpeg);
    background-position: center center;
    background-repeat: repeat;
    -webkit-background-size: cover;
    background-size: cover;
    height: 130px;
    position: relative;
}

section {
    padding: 0 !important;
}
}
