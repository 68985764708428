body {
    font-family: "Open Sans", sans-serif;
    
}
$primary_color: #e9963e;
/****************** Utilities ********************/
.btn-rounded {
    border-radius: 10em;
}
.btn-outline-amber {
    color: $primary_color !important;
    border: 1px solid $primary_color !important;
}
.btn-amber {
    background-color: $primary_color !important;
}
.text-amber {
    color: $primary_color;
}
.swiper {
    .swiper-button-next,
    .swiper-button-prev {
        width: 50px !important;
        height: 80px !important;
        background: #fff;
        padding: 10px;
        box-shadow: 1px 2px 10px -1px #0000004d;
    }
    .swiper-button-next {
        border-radius: 4px 0 0 4px;
        right: 0 !important;
    }
    .swiper-button-prev {
        border-radius: 0 4px 4px 0;
        left: 0 !important;
    }
}
.container-fluid {
    padding-left: 100px;
    padding-right: 100px;
}
/****************** Header ***********************/
.Header {
    .top_bar {
        background-color: $primary_color;
        padding: 15px;
        .top_menu {
            margin: 0;
            display: flex;
            justify-content: end;
            li {
                list-style: none;
                margin-right: 30px;
                a {
                    color: #fff;
                    font-weight: 500;

                    i {
                        color: #fff;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .navbar {
        .navbar-nav {
            align-items: center;
            .nav-item {
                &.wishlist,
                &.cart {
                    a {
                        align-items: center;
                        color: #666;
                        display: flex;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 16px;
                        max-width: 112px;
                        position: relative;
                        span.lnr {
                            color: #111;
                            font-size: 28px;
                            font-weight: 500;
                            padding-right: 10px;
                        }
                        .count {
                            background: $primary_color;
                            border-radius: 50%;
                            color: #fff;
                            font-size: 11px;
                            height: 20px;
                            left: 45px;
                            line-height: 20px;
                            position: absolute;
                            text-align: center;
                            top: -5px;
                            width: 20px;
                        }
                    }
                }
                &.active {
                    a {
                        font-weight: 600;
                    }
                }
                a {
                    font-weight: 500;
                    margin: auto 10px;
                    text-transform: capitalize;
                }
            }
        }
    }
}
/****************** Banner ***********************/

.App {
    position: relative;
    .loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffffc2;
    }
}
.mybreadcrumb {
    .breadcrumb {
        padding: 30px;
        justify-content: flex-end !important;
        a {
            color: $primary_color;
        }
    }
}
.banner {
    .banner_wrap {
        height: 650px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

/****************** about_us ***********************/
.about_us {
    padding: 3em 0;
    background-image: url(../img/abtbanner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: relative;
    &:after {
        content: " ";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff4d;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    .abt_content {
        padding: 20px;
        position: relative;
        h3 {
            font-size: 40px;
            color: #000;
            font-weight: 500;
            span {
                color: #c74a2d;
                font-weight: 600;
            }
        }
        p {
            font-size: 16px;
            color: #000;
            font-weight: 400;
        }
    }
}
/****************** all_Cart ***********************/
.cart_details {
    background-color: #fafafa;
    padding: 5em 0;
    .all_cart {
        background: #fff;
        box-shadow: #00000033 0px 1px 2px 0px;
        padding: 25px;
        .heading {
            border-bottom: 1px solid #f0f0f0;
            margin-bottom: 20px;
            padding-bottom: 20px;
            h1 {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 0px;
            }
        }
        .cart_info {
            position: relative;
            margin-bottom: 20px;
            padding-bottom: 20px;
            &:not(:last-child) {
                border-bottom: 1px solid #f0f0f0;
            }
            .pro_detail {
                .pro_thumb {
                    height: 200px;
                    overflow: hidden;
                    border-radius: 10px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .pro_detail {
                .ant-input-number-handler-wrap {
                    opacity: 1;
                }
                position: relative;
                .pro_brand {
                    font-size: 13px;
                    color: #878787;
                }
                .pro_title {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 30px;
                    a {
                        color: #000;
                    }
                }
                .pro_price {
                    span {
                        color: #878787;
                        text-decoration: line-through;
                        font-size: 14px;
                        vertical-align: middle;
                        font-weight: 500;
                    }
                }
                .pro_material {
                    color: #878787;
                }
                .pro_action {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    .price_details {
        position: sticky;
        top: 20px;
        .checkout_details {
            border-bottom: 1px solid #f0f0f0;
            margin-bottom: 20px;
            padding-bottom: 20px;
            .lists {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                span {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
}

/****************** all_product ***********************/
.all_product {
    padding: 3em 0px 0;
    .heading {
        text-align: center;
        margin: 0 0px 3em;
        h1 {
            font-size: 40px;
            color: #000;
            font-weight: 500;
            span {
                color: #c74a2d;
                font-weight: 600;
            }
        }
    }
    .swiper {
        .swiper-button-next,
        .swiper-button-prev {
            width: 30px !important;
            height: 50px !important;
            &:after {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }

    .product_cards {
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;
        box-shadow: none;
        text-align: center;
        border-radius: 25px;
        .img_wraped {
            height: 200px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:hover {
            .wishlist {
                top: 1%;
            }
            .action {
                top: 50%;
            }
        }
        h4 {
            margin-bottom: 5px;
        }
        h5 {
            font-size: 15px;
            del {
                color: #c74a2d;
            }
        }
        .action {
            position: absolute;
            top: -100%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            transition: all 0.4s ease-in-out;
        }
        .wishlist {
            position: absolute;
            top: -20%;
            right: 0;
            transition: all 0.4s ease-in-out;
            button {
                border-radius: 50%;
                width: 45px;
                height: 45px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff !important;
                color: $primary_color !important;
                i {
                    font-size: 18px;
                }
            }
        }
    }
}
.product_details {
    padding: 5em 0;
    .product_thumb {
        height: 550px;
        overflow: hidden;
        img {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .product_desc {
        padding: 20px;
        .quantity {
            width: 100%;
            input {
                height: calc(1.5em + 0.75rem + 2px);
            }
        }
        .variants {
            margin: 2em 0;
            h5 {
                color: #000;
                font-size: 15px;
            }
            .size {
                .ant-radio-button-wrapper {
                    margin-right: 10px;
                    padding: 5px 20px;
                    height: auto;
                    border: 1px solid #d9d9d9;
                    border-radius: 5px;
                    &:hover {
                        color: $primary_color;
                    }
                    &:before {
                        display: none;
                    }
                    &.ant-radio-button-wrapper-checked {
                        background: $primary_color;
                        border-color: $primary_color;
                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
            .color_filter {
                display: inline-flex;
                .color {
                    margin-right: 10px;
                    border: 1px solid #0000003f;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    font-size: 0;
                    cursor: pointer;
                    &.active {
                        border: 3px solid #b5b5b5;
                    }
                }
            }
        }
        .variants_samagri {
            margin-top: 20px;
            .pricediv {
                width: 75px;
                margin-right: 10px;
                padding: 7px;
                height: auto;
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                text-align: center;
                cursor: pointer;
                &.active {
                    background-color: $primary_color;
                    color: #fff;
                }
            }
        }
        .product_prices {
            h3 {
                color: $primary_color;
                span {
                    color: #878787;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            h5 {
                color: #878787;
                font-size: 16px;
                font-weight: 400;
            }
        }
        h1 {
            font-weight: 600;
            font-size: 40px;
        }
        h3 {
            color: #e9963e;
            font-weight: 700;
        }
        p {
            color: #444;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }
        .btn {
            padding: 10px 30px;
            color: #fff;
        }
    }
    .more_desc {
        margin: 2em 0;
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        color: $primary_color;
                    }
                }
                .ant-tabs-tab-btn {
                    font-size: 20px;
                    color: #6e6e6e;
                    text-transform: uppercase;
                    &:hover {
                        color: #000;
                    }
                }
            }
            .ant-tabs-ink-bar {
                background: $primary_color;
            }
        }
    }
}

/************** profile **************************/
.profile {
    padding: 5em 0;
}

/*********** Login & Register ********************/
.login {
    padding: 5em 0;
}
/****************** Footer ***********************/
.footer {
    padding: 5em 30px 1em;
    background: #fff7ed;
    .copyright {
        background-color: $primary_color;
        text-align: center;
        margin: 20px 0;
        padding: 15px;
        border-radius: 5px;
        p {
            margin-bottom: 0px;
            color: #fff;
            font-weight: 400;
        }
    }
    .footer_wrap {
        padding: 0 20px;
        .footer_desc {
            p {
                font-size: 16px;
                font-weight: 400;
            }
        }
        .footer_logo {
            margin-bottom: 20px;
        }
        .footer_head {
            margin-bottom: 20px;
            h2 {
                font-weight: 500;
                font-size: 25px;
            }
        }

        .footer_contact {
            ul {
                margin: 0;
                padding: 0;
                li {
                    display: flex;
                    list-style: none;
                    margin-bottom: 10px;
                    .icons {
                        width: 50px;
                        height: 50px;
                        background-color: $primary_color;
                        border-radius: 50%;
                        margin-right: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                    }
                    .box_content {
                        h3 {
                            font-size: 15px;
                            font-weight: 400;
                            color: #777;
                        }
                        p {
                            font-size: 15px;
                            font-weight: 400;
                            a {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
        .footer_links {
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    margin-bottom: 10px;
                    margin-left: 10px;
                    a {
                        color: #000;
                        position: relative;
                        font-weight: 400;
                        i {
                            color: #e9963e;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
        .newsletter_form {
            margin-top: 10px;
            .content_form {
                position: relative;
                input {
                    padding: 0 25px;
                    border-radius: 30px;
                    text-align: left;
                    width: 100%;
                    height: 55px;
                    font-size: 16px;
                }
                .form_action {
                    position: absolute;
                    top: 0;
                    right: -20px;
                    button {
                        border-radius: 50%;
                        width: 45px;
                        height: 45px;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        i {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
