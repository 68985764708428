
  @media all{ 
    .row { 
      margin-right: -15px; 
      margin-left: -15px;
    } 
  }     
  
  @media all{ 
    .container { 
      padding-right: 15px; 
      padding-left: 15px; 
      margin-right: auto; 
      margin-left: auto;
    } 
  }     
  
  @media (min-width: 768px){ 
    .container { 
      width: 750px;
    } 
  }     
  
  @media (min-width: 992px){ 
    .container { 
      width: 970px;
    } 
  }     
  
  @media (min-width: 1200px){ 
    .container { 
      width: 1170px;
    } 
  }     
  
  @media all{ 
    .container { 
      position: relative;
    } 
  }     
  
  @media all{ 
    section { 
      display:   block;
      padding:  96px 0; 
      position:  relative; 
      overflow:  hidden;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    } 
  }     
  
  @media all{ 
    .pt0 { 
      padding-top: 0;
    } 
  }     
  
  @media all{ 
    .main-container { 
      clear: both;
    } 
  }     
  
  @media all{ 
    main { 
      display: block;
    } 
  }     
  
  @media all{ 
    article { 
      display: block;
    } 
  }     
  
  @media all{ 
    .hentry { 
      margin: 0 0 1.5em;
    } 
  }     
  
  @media all{ 
    .mb64 { 
      margin-bottom: 64px;
    } 
  }     
  
  @media all{ 
    .author-bio { 
      display: block; 
      overflow: hidden; 
      padding: 32px; 
      background: #f5f5f5; 
      border: 1px solid rgba(0, 0, 0, 0.1); 
      margin-bottom: 64px;
    } 
  }     
  
  @media all{ 
    header { 
      display: block;
    } 
  }     
  
  @media all{ 
    .clear:before,.clear:after,.entry-content:before,.entry-content:after,.comment-content:before,.comment-content:after,.site-header:before,.site-header:after,.site-content:before,.site-content:after,.site-footer:before,.site-footer:after { 
      content: ""; 
      display: table; 
      table-layout: fixed;
    } 
  }     
  
  @media all{ 
    .clear:after,.entry-content:after,.comment-content:after,.site-header:after,.site-content:after,.site-footer:after { 
      clear: both;
    } 
  }     
  
  @media all{ 
    .btn-group:after ,.btn-group:before ,.btn-toolbar:after,.btn-toolbar:before,.clearfix:after,.clearfix:before,.container-fluid:after,.container-fluid:before,.container:after,.container:before,dd:after ,dd:before ,.form-group:after ,.form-group:before ,.modal-footer:after,.modal-footer:before,.modal-header:after,.modal-header:before,.nav:after,.nav:before,.navbar-collapse:after,.navbar-collapse:before,.navbar-header:after,.navbar-header:before,.navbar:after,.navbar:before,.pager:after,.pager:before,.panel-body:after,.panel-body:before,.row:after,.row:before { 
      display: table; 
      content: " ";
    } 
  }     
  
  @media all{ 
    .btn-group:after ,.btn-toolbar:after,.clearfix:after,.container-fluid:after,.container:after,dd:after ,.form-group:after ,.modal-footer:after,.modal-header:after,.nav:after,.navbar-collapse:after,.navbar-header:after,.navbar:after,.pager:after,.panel-body:after,.row:after { 
      clear: both;
    } 
  }     
  
  @media all{ 
    p { 
      margin:    0 0 10px;
      margin-bottom:   24px; 
      margin-top:  0; 
      padding:  0;
      font-weight: 400;
    } 
  }     
  
  @media all{ 
    h1 { 
      margin:       0.67em 0;
      font-size:  56px; 
      font-family:    "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight:   300; 
      line-height:  64px;
      color:   #0e1015;
      margin-top:     0; 
      margin-bottom:     24px; 
      clear:     both;
      padding:    0;
    } 
  }     
  
  @media all{ 
    .post-title { 
      font-size: 40px; 
      font-weight: 300;
    } 
  }     
  
  @media all{ 
    ul { 
      box-sizing:     border-box;
      margin-top:  0; 
      margin-bottom:  24px; 
      margin:   0 0 1.5em 3em;
      list-style:  disc;
      padding: 0;
    } 
  }     
  
  @media all{ 
    .post-meta { 
      overflow: hidden; 
      display: inline-block; 
      margin-bottom: 12px;
    } 
  }     
  
  @media all{ 
    .content ul  { 
      margin-left:  1.5em;
      list-style: disc;
    } 
  }     
  
  @media all{ 
    .nolist > ul  { 
      list-style: none; 
      margin: 0; 
      padding: 0;
    } 
  }     
  
  @media all{ 
    .content ul.post-meta  { 
      margin-left: 0;
    } 
  }     
  
  @media all{ 
    h4 { 
      font-family:    "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight:   300; 
      line-height:  32px;
      color:   #0e1015;
      margin-top:     0; 
      margin-bottom:     24px; 
      font-size:  24px; 
      clear:     both;
      padding:    0;
    } 
  }     
  
  @media all{ 
    .social_sharing { 
      width: 100%; 
      clear: both;
    } 
  }     
  
  @media all{ 
    hr { 
      height:   1px; 
      -webkit-box-sizing:     content-box; 
      -moz-box-sizing:     content-box; 
      box-sizing:    content-box; 
      margin-top:    20px; 
      margin-bottom:   1.5em;
      border:  none; 
      border-top:  1px solid #ccc; 
      margin: 0 0 24px 0; 
      width: 100%;
    } 
  }     
  
  @media all{ 
    .content hr  { 
      width: 128px; 
      max-width: 60%; 
      height: 2px; 
      background: #DDD; 
      margin: 50px auto; 
      border: 0;
    } 
  }     
  
  @media all{ 
    .col-sm-2 { 
      position: relative; 
      min-height: 1px; 
      padding-right: 15px; 
      padding-left: 15px;
    } 
  }     
  
  @media (min-width: 768px){ 
    .col-sm-2 { 
      float:  left;
      width: 16.66666667%;
    } 
  }     
  
  @media all{ 
    .col-sm-10 { 
      position: relative; 
      min-height: 1px; 
      padding-right: 15px; 
      padding-left: 15px;
    } 
  }     
  
  @media (min-width: 768px){ 
    .col-sm-10 { 
      float:  left;
      width: 83.33333333%;
    } 
  }     
  
  @media all{ 
    h3 { 
      font-family:    "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight:   300; 
      line-height:  40px;
      color:   #0e1015;
      margin-top:     0; 
      margin-bottom:     24px; 
      font-size:  32px; 
      clear:     both;
      padding:    0;
    } 
  }     
  
  @media all{ 
    a { 
      background-color:     transparent;
      color:  #745cf9; 
      text-decoration:   none; 
      outline:  none;
      font-weight: 600; 
      transition: all 0.3s ease; 
      -webkit-transition: all 0.3s ease; 
      -moz-transition: all 0.3s ease; 
      cursor: poitner;
    } 
  }     
  
  a { 
      color: #ff530a;
  } 
  
  @media all{ 
    a:active,a:hover { 
      outline: 0;
    } 
  }     
  
  @media all{ 
    a:hover { 
      color: #23527c; 
      text-decoration: underline;
    } 
  }     
  
  @media all{ 
    a:hover,a:active { 
      color:  midnightblue;
      outline: 0;
    } 
  }     
  
  @media all{ 
    a,a:active,a:hover { 
      text-decoration: none; 
      outline: none;
    } 
  }     
  
  @media all{ 
    a:hover { 
      color: #5d47d7; 
      text-decoration: none;
    } 
  }     
  
  a:hover,a:active { 
      color: #ff8c5b;
  } 
  
  @media all{ 
    .post-meta > li  { 
      float: left; 
      margin-right: 24px;
    } 
  }     
  
  @media all{ 
    .content li  { 
      margin-bottom: 0.5em; 
      line-height: 170%;
    } 
  }     
  
  @media all{ 
    .content ul > li:first-child  { 
      margin-top: 0.5em;
    } 
  }     
  
  @media all{ 
    .content ul.post-meta li  { 
      list-style: none; 
      margin-top: 0 !important;
    } 
  }     
  
  @media all{ 
    .content ul > li:last-child  { 
      margin-bottom: 0;
    } 
  }     
  
  @media all{ 
    html :where(img[class*="wp-image-"]) { 
      height: auto; 
      max-width: 100%;
    } 
  }     
  
  @media all{ 
    img { 
      border:    0;
      vertical-align:   middle;
      height:  auto; 
      max-width:  100%;
    } 
  }     
  
  @media all{ 
    .aligncenter { 
      clear:  both; 
      display: block; 
      margin-left: auto; 
      margin-right: auto;
    } 
  }     
  
  @media all{ 
    span { 
      font-weight: 400;
    } 
  }     
  
  @media all{ 
    h2 { 
      font-family:    "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight:   300; 
      line-height:  48px;
      color:   #0e1015;
      margin-top:     0; 
      margin-bottom:     24px; 
      font-size:  40px; 
      clear:     both;
      padding:    0;
    } 
  }     
  
  @media all{ 
    .ss_social_sharing a  { 
      text-indent: -99999px;
    } 
  }     
  
  @media all{ 
    a.ss_button_facebook { 
      background-image:  url(https://www.smartpuja.com/blog/wp-content/plugins/wp-social-sharing/static/socialshare.png); 
      width:  45px; 
      height:  45px; 
      background-repeat:  no-repeat; 
      padding:  0px; 
      border:  0px; 
      margin:  1% 1% 1% 0; 
      display:  inline-block; 
      /* background-repeat-x:  no-repeat; 
      background-repeat-y:  no-repeat; */
      background-position: -90px -45px;
    } 
  }     
  
  @media all{ 
    a.ss_button_facebook:hover { 
      background-position: -90px 0px; 
      transition-delay: 0s; 
      transition-duration: 250ms; 
      transition-property: all; 
      transition-timing-function: ease-in-out;
    } 
  }     
  
  @media all{ 
    a.ss_button_twitter { 
      background-image:  url(https://www.smartpuja.com/blog/wp-content/plugins/wp-social-sharing/static/socialshare.png); 
      width:  45px; 
      height:  45px; 
      background-repeat:  no-repeat; 
      padding:  0px; 
      border:  0px; 
      margin:  1% 1% 1% 0; 
      display:  inline-block; 
      /* background-repeat-x:  no-repeat; 
      background-repeat-y:  no-repeat; */
      background-position: 0px -45px;
    } 
  }     
  
  @media all{ 
    a.ss_button_twitter:hover { 
      background-position: 0px 0px; 
      transition-delay: 0s; 
      transition-duration: 250ms; 
      transition-property: all; 
      transition-timing-function: ease-in-out;
    } 
  }     
  
  @media all{ 
    a.ss-button-linkedin { 
      background-image:  url(https://www.smartpuja.com/blog/wp-content/plugins/wp-social-sharing/static/socialshare.png); 
      width:  45px; 
      height:  45px; 
      background-repeat:  no-repeat; 
      padding:  0px; 
      border:  0px; 
      margin:  1% 1% 1% 0; 
      display:  inline-block; 
      /* background-repeat-x:  no-repeat; 
      background-repeat-y:  no-repeat; */
      background-position: -135px -45px;
    } 
  }     
  
  @media all{ 
    a.ss-button-linkedin:hover { 
      background-position: -135px 0px; 
      transition-delay: 0s; 
      transition-duration: 250ms; 
      transition-property: all; 
      transition-timing-function: ease-in-out;
    } 
  }     
  
  @media all{ 
    b { 
      font-weight:  bold;
    } 
  }     
  
  @media all{ 
    .list-inline { 
      padding-left:  0; 
      margin-left:  0;
      list-style:  none;
    } 
  }     
  
  @media all{ 
    .author-social { 
      font-size: 16px !important;
    } 
  }     
  
  @media all{ 
    .social-list { 
      margin:  0;
      font-size: 22px;
    } 
  }     
  
  @media all{ 
    small { 
      font-size:  85%;
    } 
  }     
  
  @media all{ 
    h3 small  { 
      font-weight:  400; 
      line-height:  1; 
      color:  #777;
      font-size: 65%;
    } 
  }     
  
  @media all{ 
    textarea { 
      margin:         0;
      font:         inherit; 
      color:       #666; 
      overflow:          auto;
      font-family:    "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size:        14px; 
      line-height:        1.5;
      border:  1px solid ccc; 
      border-radius:      3px;
      padding-left:     3px; 
      width:  100%; 
      margin-bottom:    24px; 
      margin-top:    0; 
      padding:    0;
      -webkit-appearance:  none; 
      -moz-appearance:  none; 
      appearance:  none;
      background: #fff;
    border-radius: 5px; 
      height: 70px;
    } 
  }     
  
  @media all{ 
    input { 
      margin:     0;
      font:     inherit; 
      color:    #404040; 
      line-height:    1.5;
      font-family:  "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size:    14px; 
      margin-bottom:  24px; 
      margin-top:  0; 
      padding:  0;
    } 
  }     
  
  @media all{ 
    input[type="text"] { 
      color:    #666; 
      border:  1px solid ccc; 
      border-radius:    3px;
      padding:   3px;
      -webkit-appearance:  none; 
      -moz-appearance:  none; 
      appearance:  none;
      width: 100%; 
      background: #fff;
      border-radius: 5px; 
      height: 40px;
    } 
  }     
  
  @media all{ 
    input[type="email"] { 
      color:   #666; 
      border:  1px solid ccc; 
      border-radius:   3px;
      padding:  3px;
      width: 100%; 
      background: #fff;
    border-radius: 5px; 
      height: 40px;
    } 
  }     
  
  @media all{ 
    i { 
      font-style: italic;
    } 
  }     
  
  @media all{ 
    .fa { 
      display: inline-block; 
      font: normal normal normal 14px/1 FontAwesome; 
      font-size: inherit; 
      text-rendering: auto; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
    } 
  }     
  
  @media all{ 
    .post-meta i  { 
      font-size: 16px; 
      margin-right: 8px; 
      position: relative; 
      top: 2px;
    } 
  }     
  
  @media all{ 
    .fa-user:before { 
      content: "\f007";
    } 
  }     
  
  @media all{ 
    .fa-calendar:before { 
      content: "\f073";
    } 
  }     
  
  @media all{ 
    .fa-folder-open-o:before { 
      content: "\f115";
    } 
  }     
  
  @media all{ 
    strong { 
      font-weight:  bold;
    } 
  }     
  
  @media all{ 
    .list-inline > li  { 
      display:  inline-block; 
      padding-right:  5px; 
      padding-left:  5px;
      padding: 0 8px;
    } 
  }     
  
  @media all{ 
    .list-inline > li:first-child  { 
      padding-left: 0;
    } 
  }     
  
  @media all{ 
    .list-inline > li:last-child  { 
      padding-right: 0;
    } 
  }     
  
  @media all{ 
    input[type="checkbox"] { 
      -webkit-box-sizing:   border-box; 
      -moz-box-sizing:   border-box; 
      box-sizing:  border-box; 
      padding:  0;
      margin:  4px 0 0; 
      margin-top:  1px\9; 
      line-height:  normal;
    } 
  }     
  
  @media all{ 
    label { 
      display: inline-block; 
      max-width: 100%; 
      margin-bottom: 5px; 
      font-weight: 700;
    } 
  }     
  
  @media all{ 
    input[type="submit"] { 
      -webkit-appearance:  button; 
      cursor:  pointer;
      background: #ff530a !important; 
      border-radius: 0 !important; 
      border: 2px solid #745cf9; 
      color: #fff; 
      font-size: 11px; 
      font-weight: bold; 
      height: 50px; 
      letter-spacing: 1px; 
      line-height: 48px; 
      max-width: 250px; 
      padding: 0; 
      text-transform: uppercase; 
      width: 100%;
    } 
  }     
  
  @media all{ 
    .comments input[type="submit"]  { 
      max-width: 25%; 
      float: right;
    } 
  }     
  
  @media all{ 
     .updated:not(.published) { 
      display: none;
    } 
  }     
  
  @media all{ 
    a,a:visited { 
      text-decoration: none; 
      outline: none;
    } 
  }     
  
  @media all{ 
    a,a:visited,a:active,a:hover { 
      text-decoration: none; 
      outline: none;
    } 
  }     
  
  @media all{ 
    .fa-twitter:before { 
      content: "\f099";
    } 
  }     
  
  li a[href*="twitter.com"] .fa:before ,.fa-twitter:before { 
      content: "\f099";
  } 
  
  @media all{ 
    .fa-facebook-f:before,.fa-facebook:before { 
      content: "\f09a";
    } 
  }     
  
  li a[href*="facebook.com"] .fa:before ,.fa-facebook-f:before,.fa-facebook:before { 
      content: "\f09a";
  } 
  
  
/* media query */
@media screen and (min-width: 768px) and (max-width:1280px)   {
  .author-bio {
    margin-left: 20%;
  }
  #comments{
    margin-left: 20%;
  }
  .entry-content{
    margin-left: 20%;
  }
  .nolist{
    margin-left: 22%;
  }
}